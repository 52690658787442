import React from 'react'
import PromotionTemplate from 'templates/promotion-template'
import { graphql } from 'gatsby'

const Offer = props => {
  return <PromotionTemplate {...props} />
}

export const pageQuery = graphql`
  query offerSingleQuery(
    $id: String!
    $productList: [String!]
    $subcategoryList: [String!]
    $image: String
  ) {
    deereOffer(id: { eq: $id }) {
      description
      disclaimer
      endDate
      endDateString: endDate(formatString: "MMMM DD, YYYY")
      excerpt
      id
      imageSrc: imageUrl
      metaDescription
      metaTitle
      productList
      slug
      subcategoryList
      subTitle
      tags
      title
    }
    allDeereOffer(filter: { subcategoryList: { in: $subcategoryList } }) {
      nodes {
        id
        title
        subTitle
        image: imageUrl
        slug
        tags
        endDateString: endDate(formatString: "MMMM DD, YYYY")
      }
    }
    allDeereProduct(filter: { sku: { in: $productList } }, sort: { fields: [name], order: ASC }) {
      nodes {
        image
        imageAlt
        name
        overview
        sku
        slug
      }
    }
    image: file(id: { eq: $image }) {
      ...SharpImage800
    }
  }
`

export default Offer
